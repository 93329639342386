import {
  BrushIcon,
  GlobeIcon,
  Goal,
  MessagesSquareIcon,
  Search,
} from "lucide-react";

// External apps icons
import CIPIcon from "@shared-assets/cip.png";
import ISearchIcon from "@shared-assets/isearch.png";
import { type StaticImport } from "next/dist/shared/lib/get-img-props";
import type { FeatureFlagType } from "@shared-utils/constants";

import type { JSX } from "react";

type ExternalApp = {
  name: string;
  url: string;
  image: string | StaticImport;
};

export type Update = {
  date: string;
  key: string;
  title: string;
  url: string;
  body: string | JSX.Element;
};

type Metadata = {
  updates: Update[];
  aiTools: AITool[];
  externalApps: ExternalApp[];
};

export type AITool = {
  slug:
    | "chat-ai"
    | "training-ai"
    | "translate-ai"
    | "image-ai"
    | "isearch"
    | "docs"
    | "/";
  tool: boolean;
  requiredFeatureFlags?: FeatureFlagType[];
  meta: {
    fillColor?: string;
    strokeColor?: string;
    backgroundColor?: string;
    backgroundColorHover?: string;
    name: string;
    description: string;
    byline: string;
    contactName: string;
    contactEmail: string;
  };
};

export const getIconFromSlug = (
  slug: AITool["slug"],
  props?: {
    size?: number;
    strokeWidth?: number;
    className?: string;
  },
) => {
  switch (slug) {
    case "chat-ai":
      return <MessagesSquareIcon size={18} strokeWidth={1.5} {...props} />;
    case "translate-ai":
      return <GlobeIcon size={18} strokeWidth={1.5} {...props} />;
    case "image-ai":
      return <BrushIcon size={18} strokeWidth={1.5} {...props} />;
    case "training-ai":
      return <Goal size={18} strokeWidth={1.5} {...props} />;
    case "isearch":
      return <Search size={18} strokeWidth={1.5} {...props} />;
  }
};

export const metadata: Metadata = {
  updates: [
    {
      date: "2024-11-21",
      key: "plugins",
      title: "Plugin to ClinicalTrials.Gov available now!",
      url: "/updates",
      body: (
        <>
          {
            "You can now “chat” with ClinicalTrials.Gov content by simply selecting the “Clinical Trial Expert” profile in ChatGPT. You can also create your own Profile to best suit your needs – just click the box for the Plugin. "
          }
          <a
            className="font-semibold text-leo-light-green-600 hover:underline"
            href="https://engage.cloud.microsoft/main/org/leo-pharma.com/threads/eyJfdHlwZSI6IlRocmVhZCIsImlkIjoiMzA3MjMzNjI5MTU5NDI0MCJ9?trk_copy_link=V2"
            target="_blank"
          >
            {"Details in this message"}
          </a>
          .
        </>
      ),
    },
    {
      date: "2024-08-29",
      key: "ai-awards",
      title:
        "Call for entries to LEO AI Awards: all the details you need to know ",
      url: "/updates",
      body: (
        <>
          <p>
            Are you using LEO AI to boost the way you work and deliver business
            impact? You might be the winner we are looking for!{" "}
          </p>
          <p>
            All the details about the competition are included in this{" "}
            <a
              className="font-semibold text-leo-light-green-600 hover:underline"
              target="_blank"
              href="https://leopharma.sharepoint.com/sites/leopulse/News/Pages/Step-into-the-spotlight-with-the-launch-of-LEO-AI-awards.aspx"
            >
              Pulse article
            </a>
            .
          </p>
        </>
      ),
    },
    {
      date: "2024-08-09",
      key: "new-model",
      title: "Select GPT-4 for increased speed and precision.",
      url: "/updates",
      body: "We have enabled GPT-4 as a Profile option in LEO AI, which allows up to approx. 90,000 characters in a chat - that is the equivalent of 150-200 pages of text! Make sure you select this Profile to be able to input prompts or upload files of bigger sizes with improved speed and quality of the outputs.",
    },
    {
      date: "2024-04-24",
      key: "new-features",
      title: "Uploading your document onto LEO AI now possible.",
      url: "/updates",
      body: "An eagerly expected feature is now available on LEO AI: uploading your own document. \nUpload a file of up to 20 pages of text in “Chat GPT” if you want to: summarise, improve writing, extract key points, check for duplicates etc. \nUpload a file up to 40 MB to “Translate AI” if what you need it translated. Editable formats like Word or PowerPoint will work better, as you can edit the translated document. The algorithm can translate only text, so any images or charts included will stay in their original language. ",
    },
    {
      date: "2024-04-24",
      key: "darkmode",
      title: "Darkmode now available on LEO AI.",
      url: "/updates",
      body: "We are happy to announce that LEO AI now supports dark mode. You can now toggle between light and dark mode in the top right menu. We hope this feature will make your experience more enjoyable and easier on the eyes. If you have any feedback or suggestions, please let us know!",
    },
  ],
  aiTools: [
    {
      slug: "chat-ai",
      tool: true,
      meta: {
        fillColor: "fill-leo-petrol-600",
        strokeColor: "stroke-leo-petrol-600",
        backgroundColor: "bg-leo-petrol-600",
        backgroundColorHover: "hover:bg-leo-petrol-500",
        name: "ChatGPT",
        description:
          "Ask questions, request help on specific tasks, get creative, or upload a document to “chat with”.",
        byline: "Advanced Analytics Solutions",
        contactName: "Alex Schuleit",
        contactEmail: "alscu@leo-pharma.com",
      },
    },
    {
      slug: "isearch",
      requiredFeatureFlags: [],
      tool: true,
      meta: {
        fillColor: "fill-leo-lagune-600",
        strokeColor: "stroke-leo-lagune-600",
        backgroundColor: "bg-leo-lagune-600",
        backgroundColorHover: "hover:bg-leo-lagune-500",
        name: "iSearch",
        description:
          "An intelligent platform to help you manage & search complex databases.",
        byline: "Advanced Analytics Solutions",
        contactName: "Alex Schuleit",
        contactEmail: "alscu@leo-pharma.com",
      },
    },
    {
      slug: "training-ai",
      requiredFeatureFlags: ["TRAINING_AI__APP__189322"],
      tool: true,
      meta: {
        fillColor: "fill-leo-peacock-600",
        strokeColor: "stroke-leo-peacock-600",
        backgroundColor: "bg-leo-peacock-600",
        backgroundColorHover: "hover:bg-leo-peacock-500",
        name: "Training AI",
        description:
          "Training AI is a tool that helps you to train a machine learning model to predict the probability of a patient having a specific disease.",
        byline: "Advanced Analytics Solutions",
        contactName: "Alex Schuleit",
        contactEmail: "alscu@leo-pharma.com",
      },
    },
    {
      slug: "translate-ai",
      tool: true,
      meta: {
        fillColor: "fill-leo-aubergine-600",
        strokeColor: "stroke-leo-aubergine-600",
        backgroundColor: "bg-leo-aubergine-600",
        backgroundColorHover: "hover:bg-leo-aubergine-500",
        name: "Translate AI",
        description:
          "Use the power of AI to translate short content or an entire document - while preserving its formatting. ",
        byline: "Advanced Analytics Solutions",
        contactName: "Alex Schuleit",
        contactEmail: "alscu@leo-pharma.com",
      },
    },
    {
      slug: "image-ai",
      tool: true,
      meta: {
        fillColor: "fill-leo-sunflower-600",
        strokeColor: "stroke-leo-sunflower-600",
        backgroundColor: "bg-leo-sunflower-600",
        backgroundColorHover: "hover:bg-leo-sunflower-500",
        name: "Image GenAI",
        description: "Transform written prompts into powerful visuals. ",
        byline: "Advanced Analytics Solutions",
        contactName: "Alex Schuleit",
        contactEmail: "alscu@leo-pharma.com",
      },
    },
    {
      slug: "isearch",
      tool: false,
      meta: {
        fillColor: "fill-leo-light-green-600",
        strokeColor: "stroke-leo-light-green-600",
        backgroundColor: "bg-leo-light-green-600",
        backgroundColorHover: "hover:bg-leo-light-green-500",
        name: "iSearch",
        description:
          "iSearch is an intelligent platform supported by machine learning algorithms to help you manage complex databases easier and faster. The platform is best suited for heavy text databases and tables with multiple text columns.",
        byline: "Advanced Analytics Solutions",
        contactName: "Alex Schuleit",
        contactEmail: "alscu@leo-pharma.com",
      },
    },
    {
      slug: "docs",
      tool: false,
      meta: {
        name: "Docs",
        description: "Documentation for the Advanced Analytics Solutions team",
        byline: "Advanced Analytics Solutions",
        contactName: "Alex Schuleit",
        contactEmail: "alscu@leo-pharma.com",
      },
    },
  ],
  externalApps: [
    {
      url: "https://isearch.leopharma-app.com/",
      name: "iSearch",
      image: ISearchIcon,
    },
    {
      url: "https://cip.leopharma-app.com/",
      name: "Competitor Information Platform",
      image: CIPIcon,
    },
  ],
};
