// Supported feature flags
export const FeatureFlag = [
  "TRAINING_AI__APP__189322",
  "ISEARCH__DATA__IMPORT__197186",
] as const;
export type FeatureFlagType = (typeof FeatureFlag)[number];

// Map to print feature flags
export const featuresMap: { [key in FeatureFlagType]: string } = {
  TRAINING_AI__APP__189322: "Training AI",
  ISEARCH__DATA__IMPORT__197186: "iSearch data import",
};
